import jwt_decode from "jwt-decode";
import axios from 'axios'

export const getToken = () => {
    return localStorage.getItem("token");
};

export const getURL = () => {
    return "http://localhost:5000/";
};

export const getLoggedInUserData = () => {
    return jwt_decode(localStorage.getItem("token"));
};

export const checkIfLoggedInUserIsAdmin = () => {
    let role = jwt_decode(localStorage.getItem("token"))['roleId'];
    if(role == 3)
    {
        return true;
    }
    return false;
};

export const checkIfLoggedInUserIsImplementor = () => {
    let role = jwt_decode(localStorage.getItem("token"))['roleId'];
    if(role == 4)
    {
        return true;
    }
    return false;
};

export const checkIfLoggedInUserIsApprover = () => {
    let role = jwt_decode(localStorage.getItem("token"))['roleId'];
    if(role == 5)
    {
        return true;
    }
    return false;
};

export const checkIfLoggedInUserIsRequestor = () => {
    let role = jwt_decode(localStorage.getItem("token"))['roleId'];
    if(role == 6)
    {
        return true;
    }
    return false;
};

export const userLogout = async() => {
    let username = jwt_decode(localStorage.getItem("token"))['username'];
    axios.get(`https://asia-south1-reqapproval-407cf.cloudfunctions.net/api/logout-user/${username}`)
    .then((response)=>{
        alert('Logged Out Successfully!')
        localStorage.setItem('token', "")
        window.location = '/'
    })
    .catch((e)=>{
        alert("Some error occurred!");
        return;
    })
};