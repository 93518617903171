import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';
const MainPage = () => {

    return (
        <Container className='container text-center' >
            <h1 style={{textAlign:"center",marginTop:'30px'}}>REQUESTER-APPROVER-IMPLEMENTOR</h1>
            <div style={{display:"flex", justifyContent:'center', marginTop:'30px'}}>
                <Button onClick={()=>window.location='/login'} variant="contained">Login</Button>
            </div>
            
            <Grid container spacing={5} style={{ marginTop: '100px' }}>
                <Grid item xs={3}>
                    <Paper elevation={5}>
                        <Card style={{height: "300px"}} sx={{ minWidth: 200 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Admin
                                </Typography>
                                <Typography variant="body2">
                                    <p>Admin manages the users and assign the roles, Admin is able to see all requests and their status in real-timewith the details of the approver, requestor, implementor</p>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper elevation={5}>
                        <Card style={{height: "300px"}} sx={{ minWidth: 200 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Requester
                                </Typography>
                                <Typography variant="body2">
                                    <p>Requester is able to create requests and view their status. Whenever a request is approved or rejected, it is notified to the Requestor. Similarly, it is notified when the request is rightfully complete</p>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper elevation={5}>
                        <Card style={{height: "300px"}} sx={{ minWidth: 200 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Approver
                                </Typography>
                                <Typography variant="body2">
                                    <p>Approver can view all the requests and approve or reject their status. Upon approval, the approver shall also assign the Implementor. The Approver is notified upon an incoming request and upon completion of a request that they assigned. Can also view the status of the requests they approved/rejected</p>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper elevation={5}>
                        <Card style={{height: "300px"}} sx={{ minWidth: 200 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Implementor
                                </Typography>
                                <Typography variant="body2">
                                    <p>Is able to view the requests assigned to them. Changes the state of a request to ‘complete’upon the fulfilment of the request. Is notified when a request has been assigned to them</p>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default MainPage;