import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getLoggedInUserData, userLogout } from '../../utils';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './adminlistItems';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';

// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

import jwt_decode from "jwt-decode";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "auto",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: "none",
  borderRadius: "20px"
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {/* {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} */}
    </Typography>
  );
}

const logoutHandler = () => {
  localStorage.setItem("token", "")
  window.location = "/";
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [openModal, setOpenmodal] = React.useState(false);
  const [pendingreq, setpendingreq] = useState([])
  const [implementor, seti] = useState(null)
  const [req, setreq] = useState([]);
  const approver = getLoggedInUserData()['id'];
  const [value, setValue] = React.useState('Controlled');

  const [comopen, setcomOpen] = React.useState(true);
  const [opencomModal, setcomOpenmodal] = React.useState(false);
  const [decreq, setdecreq] = useState([]);
  const handlecommentOpen = (event) => {
    const element = event.currentTarget;
    const i = element.value;
    const comcurreq = pendingreq[i].id
    setdecreq(pendingreq[i]);
    setcomOpenmodal(true);
  }
  const handlecommentClose = () => setcomOpenmodal(false);


  const [comment, setcomment] = useState("");
  const handlecommentChange = (event) => {
    setcomment(event.target.value);
  };


  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOpen = (event) => {
    const element = event.currentTarget;
    const i = element.value;
    const curreq = pendingreq[i].id
    setreq(pendingreq[i]);
    setOpenmodal(true);
  }
  const handleClose = () => setOpenmodal(false);

  const [imp, setimp] = useState("");
  const handleChange = (event) => {
    setimp(event.target.value);
    console.log(imp);
  };

  const logoutHandler =
    async () => {
      await userLogout();
    }
  const getpendingreq = async () => {
    try {
      const appreq = await axios.get(`https://asia-south1-reqapproval-407cf.cloudfunctions.net/api/unapproved-requests`);

      setpendingreq(appreq.data);

    }
    catch (err) { console.log(err) };
  }


  const getallImplementor = async () => {
    try {
      const implementors = await axios.get(`https://asia-south1-reqapproval-407cf.cloudfunctions.net/api/users-by-role/4`);
      seti(implementors.data);
    } catch (err) {
      console.log(err);
    };
  }

  useEffect(() => {
    getpendingreq();
  }, []);
  useEffect(() => {
    getallImplementor();
  }, []);

  const acceptreq = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://asia-south1-reqapproval-407cf.cloudfunctions.net/api/approve-request",
        {
          requestID: req.id,
          approverID: approver,
          implementorID: imp
        }
      )
      .then((res) => {
        alert('Request Approved Successfully!')

        window.location = '/approver/dashboard'
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const rejectreq = (e) => {
    e.preventDefault();
    console.log(comment);
    axios
      .post(
        "https://asia-south1-reqapproval-407cf.cloudfunctions.net/api/reject-request",
        {
          requestID: decreq.id,
          approverID: approver,
          comment: comment,
        }
      )
      .then((res) => {
        alert('Request Rejected!')

        window.location = '/approver/dashboard'
      })
      .catch((e) => {
        console.log(e);
      });
  }


  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <p onClick={logoutHandler} style={{ cursor: 'pointer' }}>Logout</p>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>



            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Title</StyledTableCell>
                    <StyledTableCell align="right">Body</StyledTableCell>

                    <StyledTableCell align="right">Action</StyledTableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingreq?.map((req, index) => (
                    <>
                      <StyledTableRow key={req.id}>
                        <StyledTableCell component="th" align="right" scope="row">
                          {req.title}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {req.body}
                        </StyledTableCell>


                        <StyledTableCell align="right">
                          <Button variant="contained" onClick={handleOpen} value={`${index}`}>Accept</Button>
                          <Button style={{ marginLeft: "10px" }} variant="contained" onClick={handlecommentOpen} value={`${index}`}>Decline</Button>
                        </StyledTableCell>
                      </StyledTableRow>

                    </>

                  ))}

                  <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-title" variant="h5" component="h4">
                        <div >
                          <b> Request id : {req.id}</b>
                          <br />
                          <b>Request Title : {req.title}</b>
                          <br />
                          <br />
                          <FormControl fullWidth>
                            <InputLabel>Select Implementor</InputLabel>
                            <Select
                              id="imp"
                              name="imp"
                              value={imp}
                              label="Select Implementor"
                              onChange={handleChange}
                            >
                              {
                                implementor?.map((i, index) => (
                                  <MenuItem value={`${i.id}`}>{i.id} : {i.username}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                          <div>
                            <br />
                            <Button style={{ backgroundColor: "#00007b", color: " white" }} onClick={acceptreq}>
                              Confirm and Accept
                            </Button>
                          </div>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>

                  <Modal
                    open={opencomModal}
                    onClose={handlecommentClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-title" variant="h5" component="h4">
                        <div >
                          <b> Request id : {decreq.id}</b>
                          <br />
                          <b>Request Title : {decreq.title}</b>
                          <br />
                          <br />
                          <FormControl fullWidth>
                            <TextField
                              id="outlined-multiline-static"
                              label="Enter reason for decline"
                              multiline
                              rows={4}
                              value={comment}
                              onChange={handlecommentChange}
                              // defaultValue="Default Value"
                            />
                          </FormControl>
                          <div>
                            <br />
                            <Button style={{ backgroundColor: "#e60000", color: " white" }} onClick={rejectreq}>
                              Confirm and Decline
                            </Button>
                          </div>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>


                </TableBody>
              </Table>
            </TableContainer>

          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function ApproverDashboard() {



  return <DashboardContent />;
}