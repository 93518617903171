


import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getLoggedInUserData, userLogout } from '../../utils';
// import { getURL, getToken } from '../utils/index';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './adminlistItems';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

import jwt_decode from "jwt-decode";


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {/* {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} */}
    </Typography>
  );
}

const logoutHandler = () => {
  localStorage.setItem("token", "")
  window.location = "/";
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
      border: 0,
  },
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [allreq, setallreq] = useState([])
  
  const logoutHandler = 
 async () => {
    await userLogout();
  }
  const getallreq = async()=>{
    try{
        const appreq = await axios.get(`https://asia-south1-reqapproval-407cf.cloudfunctions.net/api/requestor-requests/${getLoggedInUserData()['id']}`);
        
        setallreq(appreq.data);
       
    }
    catch(err){console.log(err)};
}

useEffect(()=>{
    getallreq();
},[]);

const statuses = ["","Requested", "Requested", "Rejected", "Completed"];
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <p onClick={logoutHandler} style={{cursor: 'pointer'}}>Logout</p>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            
             
                
            <TableContainer component={Paper}>
               <Table sx={{ minWidth: 400 }} aria-label="customized table">
                     <TableHead>
                         <TableRow>
                            <StyledTableCell align="right">Title</StyledTableCell>
                            <StyledTableCell align="right">Body</StyledTableCell>
                                                    
                              <StyledTableCell align="right">Action</StyledTableCell>
                              <StyledTableCell align="right">Comment</StyledTableCell>

                       </TableRow>
                   </TableHead>
                   <TableBody> 
                   {allreq?.map((req, index) => (
                            <>
                                <StyledTableRow key={req.id}>
                                    <StyledTableCell component="th" align="right" scope="row">
                                        {req.title}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {req.body}
                                    </StyledTableCell>
                                    
                                    <StyledTableCell align="right">
                                    {
                                        req.statusId == 1 && (
                                          <Button variant="outlined" color='primary'>{statuses[req.statusId]}</Button>
                                        )
                                      }
                                      {
                                        req.statusId == 2 && (
                                          <Button variant="outlined">{statuses[req.statusId]}</Button>
                                        )
                                      }
                                      {
                                        req.statusId == 3 && (
                                          <Button variant="contained" color='error'>{statuses[req.statusId]}</Button>
                                        )
                                      }
                                      {
                                        req.statusId == 4 && (
                                          <Button variant="contained" color='success'>{statuses[req.statusId]}</Button>
                                        )
                                      }
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {req.comment}
                                    </StyledTableCell>
                                </StyledTableRow>

                            </>

                        ))}                    
                  </TableBody>
              </Table>
          </TableContainer>
           
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function AllRequests() {
 
  return <DashboardContent />;
}