import axios from 'axios'
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import jwt_decode from "jwt-decode";
import {getToken, getURL} from '../utils'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function LoginPage() {
  const handleSubmit = async(event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let body = {
        username: data.get('username'),
        password: data.get('password'),
    }
    axios.post('https://asia-south1-reqapproval-407cf.cloudfunctions.net/api/login', body, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((response)=>{
        localStorage.setItem('token', response.data['token'])
        var userData = jwt_decode(response.data['token']);
        console.log(userData)
        if(userData['roleId'] === 3)
        {
            window.location = '/admin/dashboard'
        }
        else if(userData['roleId'] === 4)
        {
            window.location = '/implementor/dashboard'
        }
        else if(userData['roleId'] === 5)
        {
            window.location = '/approver/dashboard'
        }
        else
        {
            window.location = '/requestor/dashboard'
        }
    })
    .catch((e)=>{
        if(e.response.status === 404)
        {
            alert('Username does not exist!')
        }
        else if(e.response.status === 403)
        {
            alert('Wrong Password')
        }
        else
        {
            alert('Server Error')
        }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}