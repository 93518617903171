import { Router } from 'react-router-dom';
// import { BrowserRouter as Route, Routes } from 'react-router-dom';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import Approver from './components/Approver';
import Requester from './components/Requester';
import Implementor from './components/Implementor';
import Login from './components/Login';
import Register from './components/Register';
import AdminDashboard from './components/AdminDashboard';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Repos from './components/Repos';



import MainPage from './Pages/MainPage';
import LoginPage from './Pages/LoginPage';
import AdminDashboardPage from './Pages/Admin/AdminDashboardPage';
import CreateUserPage from './Pages/Admin/CreateUserPage';
import AddRepoPage from './Pages/Admin/CreateRepoPage';
import ReposPage from './Pages/Admin/ReposPage';
import RequestsPage from './Pages/Admin/RequestsPage';
import UsersPage from './Pages/Admin/UsersPage';
import CreateRequest from './Pages/Requestor/CreateRequest';
import RequestorDashboard from './Pages/Requestor/RequestorDashboard'
import AllRequests from './Pages/Requestor/AllRequests';
import ApproverDashboard from './Pages/Approver/ApproverDashboard';
import AllApprovedRequestsPage from './Pages/Approver/AllApprovedRequestsPage';
import AllRejectedRequests from './Pages/Approver/AllRejectedRequests';
import PendingRequests from './Pages/Approver/PendingRequests';
import PendingRequestVerdictPage from './Pages/Approver/PendingRequestVerdictPage';
import CompletedRequestsPage from './Pages/Implementor/CompletedRequestsPage';
import ImplementorDashboardPage from './Pages/Implementor/ImplementorDashboardPage';
import IncompleteRequestsPage from './Pages/Implementor/IncompleteRequestsPage';
import IndividualUser from './Pages/IndividualUser';
import IndividualRequest from './Pages/IndividualRequest';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/2" element={<Home/>}></Route>
        <Route path="/requester2" element={<Requester/>}>   </Route>
        <Route path="/approver2" element={<Approver/>}>   </Route>
        <Route path="/implementor2" element={<Implementor/>}>   </Route>
        <Route path="/admindash2" element={<AdminDashboard/>}>   </Route>
        <Route path="/login2" element={<Login/>}>   </Route>
        <Route path="/register2" element={<Register/>}>   </Route>
        <Route path="/Repos2" element={<Repos/>}>   </Route> */}



        <Route path="/" element={<MainPage/>}>   </Route>
        <Route path="/login" element={<LoginPage/>}>   </Route>
        <Route path="/admin/dashboard" element={<AdminDashboardPage/>}>   </Route>
        <Route path="/admin/repos" element={<ReposPage/>}>   </Route>
        <Route path="/admin/requests" element={<RequestsPage/>}>   </Route>
        <Route path="/admin/users" element={<UsersPage/>}>   </Route>
        <Route path="/admin/create-user" element={<CreateUserPage/>}>   </Route>
        <Route path="/admin/add-repo" element={<AddRepoPage/>}>   </Route>


        <Route path="/requestor/create-request" element={<CreateRequest/>}>   </Route>
        <Route path="/requestor/dashboard" element={<RequestorDashboard/>}>   </Route>
        <Route path="/requestor/all-requests" element={<AllRequests/>}>   </Route>

        <Route path="/approver/dashboard" element={<ApproverDashboard/>}>   </Route>
        <Route path="approver/approved-requests" element={<AllApprovedRequestsPage/>}>   </Route>
        <Route path="approver/rejected-requests" element={<AllRejectedRequests/>}>   </Route>
        <Route path="approver/pending-requests" element={<PendingRequests/>}>   </Route>
        <Route path="approver/request-verdict/:id" element={<PendingRequestVerdictPage/>}>   </Route>

        <Route path="/implementor/completed-requests" element={<CompletedRequestsPage/>}>   </Route>
        <Route path="/implementor/dashboard" element={<ImplementorDashboardPage/>}>   </Route>
        <Route path="/implementor/incomplete-requests" element={<IncompleteRequestsPage/>}>   </Route>

        <Route path="/request/:id" element={<IndividualRequest/>}>   </Route>
        <Route path="/user/:username" element={<IndividualUser/>}>   </Route>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
